import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Typography,
  createStyles,
  Theme,
  makeStyles,
  Box,
  withStyles,
  Button,
  Tab,
  FormControl,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { colors } from 'utils/colors';
import MuiTabs from '@material-ui/core/Tabs';
import { SHARED_ROUTE, getUrlFirstParam, sharedUtilitiesTabs } from 'utils/Utils';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ConfigContext } from 'context/ConfigContext';
import { useFunctionAPI } from 'context/fakeAPIHooks/useFunctionAPI';
import { errorMsg, successMsg } from 'components/SnackbarUtilsConfigurator';
import { JSONSchema6 } from 'json-schema';
import { useDataMapDiagram } from 'map-editor/src';
import { useSchemaLookup } from 'utils/useSchemaLookup';
import { convertV2FormTemplateToJsonSchema } from 'pages/aggregates/utils/V2FormTemplateToJsonSchema';
import { sample } from 'pages/aggregates/utils/sampleForm';
import _ from 'lodash';
import useCommonStyles from 'views/useCommonStyles';
import VersionSelect from '../../records/components/common/VersionSelect';

const OptionsContainer = () => {
  const { getFunctions, getFunction, config } = useContext(ConfigContext);
  const params = useParams() as { aggregate: string };
  const FunctionAPI = useFunctionAPI();
  const { aggregate, version: selectedVersion } = params as {
    aggregate: string;
    version: string;
  };
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<string>('internalText');
  const inputActualSchemaRef = useRef<JSONSchema6 | null>(null);
  const ouputActualSchemaRef = useRef<JSONSchema6 | null>(null);
  const classes = useStyles();
  const useClasses = useCommonStyles();
  const tempRef = useRef({});
  const Tabs = withStyles({
    root: {
      '& .MuiTabs-flexContainer': {
        gap: '2%',
      },
    },
  })(MuiTabs);

  const handleTabChange = (event: React.ChangeEvent<{}>, tab: number) => {
    const path = sharedUtilitiesTabs[tab].key;
    setSelectedTab(path);
    navigate(`${SHARED_ROUTE}/${aggregate}/version/${selectedVersion}/${path}`);
  };

  const updateActiveTab = useCallback(() => {
    const pathName = location.pathname;
    const ignore = `${SHARED_ROUTE}/${aggregate}/version/${selectedVersion}/`;
    const route = getUrlFirstParam(pathName, ignore);
    const index = sharedUtilitiesTabs.findIndex((x) => x.key === route);
    setCurrentTab(index);
  }, [aggregate, selectedVersion, location]);

  useEffect(() => {
    updateActiveTab();
  }, [location, updateActiveTab]);

  const localSchemas = useMemo(() => {
    return {
      DATA: {
        schema:
          getFunction(aggregate)?.versions[+selectedVersion]?.input ||
          convertV2FormTemplateToJsonSchema(sample),
        schemaLabel: 'Input',
      },
      STATE: {
        schema:
          getFunction(aggregate)?.versions[+selectedVersion]?.output ||
          convertV2FormTemplateToJsonSchema(sample),
        schemaLabel: 'Output',
      },
    };
  }, [aggregate, selectedVersion, getFunction]);

  const schemaLookup = useSchemaLookup({
    localSchemas: localSchemas,
  });
  const {
    model,
    engine,
    checkModelHasChanged,
    hasSetPosition,
    setInitialDataMap,
    resetMapDiagram
  } = useDataMapDiagram({
    scenario: 'FUNCTION_MAPPING',
    schemaLookup: schemaLookup,
    dataMap: config.functions
      ? config.functions[aggregate]?.versions[+selectedVersion]?.aggregateMap
      : undefined,
  });

  const onDiscard = async () => {
    resetMapDiagram();
  };

  const eventMapProps = {
    model,
    engine,
    checkModelHasChanged,
    hasSetPosition,
    onDiscard,
    setInitialDataMap,
  };

  const onSave = async () => {
    if (inputActualSchemaRef.current && currentTab === 1) {
      const { error } = await FunctionAPI.updateInputSchema(
        aggregate,
        +selectedVersion,
        inputActualSchemaRef.current
      );
      tempRef.current = {};
      if (error) return;
      successMsg('The Input Schema has been saved');
    }
    if (ouputActualSchemaRef.current && currentTab === 2) {
      const { error } = await FunctionAPI.updateOutputSchema(
        aggregate,
        +selectedVersion,
        ouputActualSchemaRef.current
      );
      tempRef.current = {};
      if (error) return;
      successMsg('The Output Schema has been saved');
    }

    const thing = model?.getMapDefinition();
    if (thing && currentTab === 3) {
      const { error } = await FunctionAPI.updateFunctionMap(aggregate, +selectedVersion, thing);
      if (error) return;
      successMsg('Function Map has been saved');
    }
  };
  const handleInputSchema = (schema: JSONSchema6) => {
    inputActualSchemaRef.current = schema;
  };
  const handleOuputSchema = (schema: JSONSchema6) => {
    ouputActualSchemaRef.current = schema;
  };
  const handleAddFunctionVersion = async (name: string, versionIndex: number) => {
    const { error } = await FunctionAPI.addFunctionVersion(name, versionIndex);
    if (error) return;
    successMsg(`New version of the "${name}" function has been successfully created`);
  };
  const handleRemoveFunctionVersion = async (
    name: string,
    versionIndex: number,
    versionNumber: number
  ) => {
    const versions = getFunctions()[name].versions;
    if (versions?.length === 1) {
      return errorMsg(
        'At least one version of the function must remain. Consider deleting the entire function instead'
      );
    }
    const { error } = await FunctionAPI.removeFunctionVersion(name, versionIndex);
    if (error) return;
    successMsg(
      `Version "${versionNumber}" of the function "${name}" has been successfully deleted.`
    );
  };
  return (
    <Box>
      <Box className={[useClasses.titleBarContainer, classes.infoContainer].join(' ')}>
        <Box className={classes.aggregateContainer}>
          <Typography className={classes.aggregateTitle}> {aggregate}</Typography>
          <FormControl>
            {getFunctions()[aggregate]?.versions.length > 0 && (
              <VersionSelect
                type="function"
                versionNos={_.map(getFunctions()[aggregate]?.versions || [], 'versionNumber')}
                name={aggregate}
                onDeleteVersion={handleRemoveFunctionVersion}
                onAddVersion={handleAddFunctionVersion}
                selectedVersionIndex={Number(selectedVersion)}
                versionChangePath={`${SHARED_ROUTE}/${aggregate}/version/versionIndex/${selectedTab}`}
              />
            )}
          </FormControl>
        </Box>
        <Button
          onClick={() => onSave()}
          className={classes.saveBtn}
          startIcon={<FontAwesomeIcon icon={faSave} />}
        >
          Save
        </Button>
      </Box>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="tabs"
        className={classes.tabs}
      >
        {sharedUtilitiesTabs.map((tab) => (
          <Tab
            className={classes.tabItem}
            key={tab.label}
            label={<Typography className={classes.tab}>{tab.label}</Typography>}
          />
        ))}
      </Tabs>

      <Box className={classes.tabContainer}>
        <Outlet
          context={{
            aggregate,
            version: selectedVersion,
            handleInputSchema,
            handleOuputSchema,
            eventMapProps,
            onSave,
            schemaRef: tempRef,
          }}
        />
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoContainer: {
      alignItems: 'baseline',
    },
    saveBtn: {
      borderRadius: '5px',
      border: `1px solid ${colors.greySeaShell}`,
      background: colors.white,
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
      padding: '11px 23px 10px 20px',
      height: '40px',
      width: '111px',
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '100%',
    },
    aggregateContainer: {
      display: 'flex',
    },
    tabItem: {
      width: '40%',
      minWidth: 0,
      padding: 0,
      maxWidth: '114px',
    },
    versionTxt: {
      '& .MuiSelect-root': {
        color: colors.black,
        fontSize: '16px !important',
        fontWeight: 400,
      },
    },
    tabs: {
      borderBottom: `1px solid ${colors.black10}`,
      marginTop: 13,
    },
    versionMenu: {
      height: '35px',
      width: '162px',
      borderRadius: 5,
    },
    aggregateTitle: {
      fontSize: '24px',
      fontWeight: 500,
      fontStyle: 'normal',
      paddingRight: '12px',
    },
    tab: {
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 400,
      textTransform: 'none',
      width: 'auto',
      minWidth: '0px !important',
    },
    tabContainer: {
      marginTop: '22px',
      height: '100vh',
      paddingBottom: 10,
    },
  })
);
export default OptionsContainer;
