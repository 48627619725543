import { faPlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { V2ListComponent } from '@terragotech/page-renderer';
import { JSONSchema6 } from 'json-schema';
import { isEqual } from 'lodash';
import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import MapperItem from '../../components/FormDialog/MapperItem';
import { useConfirmDialog } from '../../context/ConfirmContext';
import { checkDuplicatePageName } from '../../pages/aggregates/utils/pageUtils';
import { CONFIRMATION, expandPath } from '../../utils/Utils';
import { colors } from '../../utils/colors';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { TGSelect, TextInputTG } from '../../views/components/formElements';
import ModalFrame from './ModalFrame';
import { useCustomPageStyles } from './commonStyles';
import { PageContext } from './contexts/PageContext';
import usePageRedirect from './hooks/usePageRedirect';
import { usePageSchemas } from './hooks/usePageSchemas';
import { getAggregateList } from 'utils/jsonPartsGenerators';
import { ConfigContext } from 'context/ConfigContext';

export type ListTemplateWithName = V2ListComponent & {
  name: string;
  droppableId: string;
  label: string;
};

interface ListEditFormProps {
  onSubmit: (result: ListTemplateWithName) => void;
  onClose: () => void;
  component: ListTemplateWithName;
}

export const ListEditForm: React.FC<ListEditFormProps> = ({ onSubmit, onClose, component }) => {
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState(component.label);
  const [type] = useState(component.type);
  const [height, setHeight] = useState(component.height || '');
  const [computedMap, setComputedMap] = useState(component.computedMap || undefined);
  const [calculatedOpen, setCalculatedOpen] = useState(false);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const [displayOptions, setDisplayOptions] = useState(component.displayOptions || undefined);
  const { config } = useContext(ConfigContext);
  const aggTypes = Object.values(getAggregateList(config));
  const compColumns = component.columns;
  const inputColumns = useMemo(
    () => compColumns?.map((column) => [column.field, column.type, column.headerName]),
    [compColumns]
  );
  const [columns, setColumns] = useState<string[][]>(inputColumns || []);
  const classes = useCustomPageStyles();
  const { isForceLeaveConfirmed } = usePageRedirect();
  const [existingNameError, setExistingNameError] = useState(false);
  const [emptyNameError, setEmptyNameError] = useState(false);
  const [emptyLabelError, setEmptyLabelError] = useState(false);
  const { pageDefinition } = useContext(PageContext);
  const { openConfirmation } = useConfirmDialog();
  const pageSchemas = usePageSchemas();

  const nameHelperTxt =
    'Duplicate name error. The name must be unique across all workflow elements';

  useEffect(() => {
    setExistingNameError(checkDuplicatePageName(name, component, pageDefinition.elements));
    setEmptyNameError(checkEmptyText(name));
  }, [name, component, pageDefinition.elements]);

  useEffect(() => {
    setEmptyLabelError(checkEmptyText(label));
  }, [label]);

  const checkEmptyText = (text: string) => {
    return text.trim() === '';
  };


  const parts = (component.droppableId || '').split('.');
  const t =
    !component.droppableId || component.droppableId === 'page'
      ? []
      : expandPath(parts, pageSchemas.FORM.schema.properties as JSONSchema6);

  let pageValue = useMemo(() => {
    return {
      value: {
        type: 'object',
        properties: {
          values: {
            type: 'array',
            items: {
              type: 'object',
              properties: {},
            },
          },
        },
      },
    };
  }, []);
  for (let i = 0; i < t.length; i++) {
    pageValue = { ...pageValue, ...t[i] };
  }

  const errorWarningSchemas: LocalSchemaDefinition = useMemo(
    () => ({
      FORMVALUE: {
        schema: {
          type: 'object',
          properties: pageValue,
        } as JSONSchema6,
        schemaLabel: 'Current Field Value',
      },
      ...pageSchemas,
    }),
    [pageValue, pageSchemas]
  );

  const computedSchema: LocalSchemaDefinition = useMemo(
    () => ({
      ...pageSchemas,
      FORMVALUE: {
        schema: {
          type: 'object',
          properties: pageValue,
        } as JSONSchema6,
        schemaLabel: 'Current Field Value',
      },
      COMPUTED: {
        schemaLabel: 'List Data',
        schema: {
          type: 'object',
          properties: {
            result: {
              type: 'object',
              properties: {
                values: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {},
                  },
                },
              },
            },
          },
        },
      },
    }),
    [pageSchemas, pageValue, component.name]
  );

  const getFormValues = useCallback(() => {
    const transformedColumns = columns.map((eachArr) => ({
      field: eachArr[0],
      type: eachArr[1],
      headerName: eachArr[2],
    }));
    return {
      name,
      droppableId: component.droppableId,
      label,
      type,
      height,
      columns: transformedColumns,
      computedMap,
      displayOptions,
    };
  }, [columns, name, component.droppableId, label, type, height, computedMap, displayOptions]);

  const isFormDirty = () => !isEqual(component, getFormValues());

  const handleClose = async () =>
    (!isFormDirty() || (isFormDirty() && (await isForceLeaveConfirmed({ handleSubmit })))) &&
    onClose();

  const handleSubmit = async () => {
    if (emptyNameError || emptyLabelError || existingNameError) {
      return;
    } else onSubmit(getFormValues());
  };

  const handleClearComputedMapper = useCallback(async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setComputedMap(undefined);
    }
  }, [openConfirmation]);

  const handleClearDisplayMapper = useCallback(async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setDisplayOptions(undefined);
    }
  }, [openConfirmation]);

  const handleAddColumn = useCallback(() => {
    setColumns((prevColumns) => [...prevColumns, ['', '']]);
  }, []);

  const handleFieldNameChange = useCallback((index: number, value: string) => {
    setColumns((prevColumns) => {
      const newColumns = [...prevColumns];
      newColumns[index][0] = value;
      return newColumns;
    });
  }, []);

  const handleFieldTypeChange = useCallback((index: number, value: string) => {
    setColumns((prevColumns) => {
      const newColumns = [...prevColumns];
      newColumns[index][1] = value;
      return newColumns;
    });
  }, []);

  const handleColumnHeaderNameChange = useCallback((index: number, value: string) => {
    setColumns((prevColumns) => {
      const newColumns = [...prevColumns];
      newColumns[index][2] = value;
      return newColumns;
    });
  }, []);

  const handleDeleteColumn = useCallback((index: number) => {
    setColumns((prevColumns) => {
      const newColumns = [...prevColumns];
      newColumns.splice(index, 1);
      return newColumns;
    });
  }, []);

  return (
    <ModalFrame
      {...{ name: component.name, type: component.type, classes, handleClose, handleSubmit }}
    >
      <Box
        style={{
          maxHeight: '80vh',
          padding: '0 16px',
        }}
      >
        <TextInputTG
          autoFocus
          id="Name"
          label="Name"
          error={existingNameError || emptyNameError}
          helperText={emptyNameError ? 'Name cannot be empty' : existingNameError ? nameHelperTxt : ''}
          value={name}
          onChange={(value) => setName(value || '')}
          fullWidth
          style={styles.inputStyles}
          labelRoot={classes.Root}
        />
        <TextInputTG
          id="Label"
          label="Label"
          error={emptyLabelError}
          helperText={emptyLabelError ? 'Label cannot be empty' : ''}
          value={label}
          onChange={(value) => setLabel(value || '')}
          fullWidth
          style={styles.inputStyles}
          labelRoot={classes.Root}
        />
        <TextInputTG
          id="Height"
          label="Height"
          value={height}
          onChange={(value) => setHeight(value || '')}
          fullWidth
          labelRoot={classes.Root}
        />
        <div style={styles.mapperTopMargin}>
          <MapperItem
            {...{
              onToggleMapper: setConditionalOpen,
              isActive: displayOptions !== undefined,
              clearMapper: handleClearDisplayMapper,
              openDataMap: conditionalOpen,
              dataMap: displayOptions,
              setDataMap: setDisplayOptions,
              localSchemaDefinition: errorWarningSchemas,
              title: 'Display Options',
              mapScenario: 'DISPLAY_OPTIONS',
              containerStyle: classes.mapperContainer,
            }}
          />
        </div>
        <div style={styles.mapperTopMargin}>
          <MapperItem
            {...{
              onToggleMapper: setCalculatedOpen,
              isActive: computedMap !== undefined,
              clearMapper: handleClearComputedMapper,
              openDataMap: calculatedOpen,
              dataMap: computedMap,
              setDataMap: setComputedMap,
              localSchemaDefinition: computedSchema,
              title: 'Calculation',
              mapScenario: 'COMPUTED_MAPPING',
              containerStyle: classes.mapperContainer,
            }}
          />
        </div>
        <div style={styles.columnTableHeader}>
          <Typography className={classes.text}>Columns</Typography>
          <IconButton onClick={handleAddColumn}>
            <FontAwesomeIcon style={styles.plusTrashIcon} icon={faPlus} />
          </IconButton>
        </div>
        <TableContainer component={Paper} style={styles.tableContainerMargin}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                <TableCell>Property</TableCell>
                <TableCell>Label</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {columns.map((column, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteColumn(index)}>
                      <FontAwesomeIcon style={styles.plusTrashIcon} icon={faTrashCan} />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <TextInputTG
                      value={column[0]}
                      onChange={(value) => handleFieldNameChange(index, value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextInputTG
                      value={column[2]}
                      onChange={(value) => handleColumnHeaderNameChange(index, value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TGSelect
                      value={column[1]}
                      options={[
                        'DateTime',
                        'Date',
                        'JSON',
                        'Float',
                        'String',
                        'Boolean',
                        'Int',
                        'PhotoCollection',
                        'FileCollection',
                      ]}
                      onChange={(value) => handleFieldTypeChange(index, value)}
                      id={index.toString()}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ModalFrame>
  );
};

const styles = {
  inputStyles: {
    paddingBottom: '20px',
  },
  tableContainerMargin: {
    marginTop: '10px',
  },
  plusTrashIcon: { fontSize: 22, color: colors.black54 },
  columnTableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mapperTopMargin: { marginTop: '20px' },
};

export default ListEditForm;
